<template>
  <v-card class="py-2">
    <v-subheader>Listado llamadas a impagados</v-subheader>
    <v-divider class />
    <v-data-table
      :headers="listadoMensajesHeaders"
      :items="listadoMensajes"
      fixed-header
      item-key="idMensaje"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [10, 25, 50],
      }"
      checkbox-color="secondary"
    >
      <template v-slot:body.prepend="{ headers }">
        <table-filters :headers="headers" :items="listadoMensajes" v-model="listadoMensajesFilters"></table-filters>
      </template>

      <template v-slot:item.tipo="{ item }">
        <status-chip v-if="item.tipo" small :value="item.tipo" />
      </template>

      <template v-slot:item.mensaje="{ item }">
        <span
          v-html="(item.mensaje || '').replace(/<[^>]*>/g, '')"
          class="text-truncate d-inline-block"
          style="max-width: 350px"
        ></span>
      </template>

      <template v-slot:item.fecha="{ item }">
        <span v-text="parseDate(item.fecha, false, true, true)"></span>
      </template>

      <template v-slot:item.NumeroFactura="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              link
              @click="verPDFfactura(item.IdDocumento)"
              color="secondary"
              text
              :disabled="!!pdfId || !item.IdDocumento"
              :loading="pdfId == item.IdDocumento && pdfId !== null"
            >
              {{ item.SerieFactura }} {{ item.NumeroFactura }}
              <v-icon right>
                {{
                  item.IdDocumento
                    ? "mdi-file-document-edit-outline"
                    : "mdi-file-cancel-outline"
                }}
              </v-icon>
            </v-btn>
          </template>
          <span>Ver PDF factura</span>
        </v-tooltip>
      </template>

      <template v-slot:item.CodigoContrato="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              link
              :to="`/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`"
              color="secondary"
              text
            >{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn>
          </template>
          <span>Detalles del contrato</span>
        </v-tooltip>
      </template>

      <template v-slot:item.Identificador="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              link
              :to="`/clientes/detalles?idCliente=${item.IdCliente}`"
              color="secondary"
              text
            >{{ item.Identificador }}</v-btn>
          </template>
          <span>Detalles del cliente</span>
        </v-tooltip>
      </template>

      <template v-slot:item.acciones="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              @click="
  tab = 0;
detailsId = item.IdCarteraCobro;
              "
              icon
              color="secondary"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          Ver detalles
        </v-tooltip>
        <!-- <v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								class="ml-2"
								color="primary"
								icon
								@click="tab = 1; detailsId = item.IdCarteraCobro"
							>
								<v-badge :value="Number(item.nLlamadas)" :content="item.nLlamadas" overlap>
									<v-icon>mdi-phone</v-icon>
								</v-badge>
							</v-btn>
						</template>
						Nº Llamadas
        </v-tooltip>-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="ml-2"
              color="secondary"
              icon
              @click="
  tab = 2;
detailsId = item.IdCarteraCobro;
              "
            >
              <v-icon>mdi-email-send-outline</v-icon>
            </v-btn>
          </template>
          Gestión de avisos formales
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { perColumnFilter, parseDate, downloadFile } from "@/utils/index.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
  },
  props: {
    listadoMensajes: { type: Array, optional: false },
    value: { type: Number | String, optional: false },
    _tab: { type: Number | String, optional: false },
  },
  computed: {
    detailsId: {
      get() { return this.value },
      set(v) { this.$emit('detailsId', v) }
    },
    tab: {
      get() { return this.value },
      set(v) { this.$emit('tab', v) }
    },
  },
  data() {
    return {
      pdfId: null,

      listadoMensajesFilters: {},
      listadoMensajesHeaders: [
        { text: "Método", value: "tipo", dataType: "select" },
        { text: "Fecha mensaje", value: "fecha", dataType: "date" },
        { text: "Mensaje", value: "mensaje" },
        { text: "Identificador", value: "Identificador" },
        { text: "Cliente", value: "denominacion" },
        { text: "Contrato", value: "CodigoContrato" },
        { text: "Factura", value: "NumeroFactura" },
        { text: "usuario", value: "usuario" },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          filterable: false,
        },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-2 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.listadoMensajesFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  methods: {
    parseDate,
    verPDFfactura(idDocumento) {
      this.pdfId = idDocumento;
      let str = this.$store.getters.getDatosEmpresa;
      const { IdUsuario } = str.iss;
      const IdEmpresa = this.$store.getters.getIdEmpresa;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
        responseType: "blob",
      })
        .then((res) => {
          let pdf = new File(
            [res.data],
            `factura_${new Date().getTime()}.pdf`,
            {
              type: "application/pdf",
            }
          );
          downloadFile(pdf, true);
          this.pdfId = null;
        })
        .catch((err) => {
          this.$root.$emit("snack", "Ha ocurrido un error");
          this.pdfId = null;
        });
    },
  },
};
</script>

<style>
</style>