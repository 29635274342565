var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-2"},[_c('v-subheader',[_vm._v("Listado llamadas a impagados")]),_c('v-divider',{}),_c('v-data-table',{attrs:{"headers":_vm.listadoMensajesHeaders,"items":_vm.listadoMensajes,"fixed-header":"","item-key":"idMensaje","footer-props":{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [10, 25, 50],
      },"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
      var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.listadoMensajes},model:{value:(_vm.listadoMensajesFilters),callback:function ($$v) {_vm.listadoMensajesFilters=$$v},expression:"listadoMensajesFilters"}})]}},{key:"item.tipo",fn:function(ref){
      var item = ref.item;
return [(item.tipo)?_c('status-chip',{attrs:{"small":"","value":item.tipo}}):_vm._e()]}},{key:"item.mensaje",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-truncate d-inline-block",staticStyle:{"max-width":"350px"},domProps:{"innerHTML":_vm._s((item.mensaje || '').replace(/<[^>]*>/g, ''))}})]}},{key:"item.fecha",fn:function(ref){
      var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.fecha, false, true, true))}})]}},{key:"item.NumeroFactura",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","color":"secondary","text":"","disabled":!!_vm.pdfId || !item.IdDocumento,"loading":_vm.pdfId == item.IdDocumento && _vm.pdfId !== null},on:{"click":function($event){return _vm.verPDFfactura(item.IdDocumento)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.SerieFactura)+" "+_vm._s(item.NumeroFactura)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(item.IdDocumento ? "mdi-file-document-edit-outline" : "mdi-file-cancel-outline")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver PDF factura")])])]}},{key:"item.CodigoContrato",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":("/contratos/detalles?codigoContrato=" + (item.CodigoContrato) + "&idContrato=" + (item.IdContrato)),"color":"secondary","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(Math.abs(Number(item.CodigoContrato))))])]}}],null,true)},[_c('span',[_vm._v("Detalles del contrato")])])]}},{key:"item.Identificador",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":("/clientes/detalles?idCliente=" + (item.IdCliente)),"color":"secondary","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.Identificador))])]}}],null,true)},[_c('span',[_vm._v("Detalles del cliente")])])]}},{key:"item.acciones",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){_vm.tab = 0;
_vm.detailsId = item.IdCarteraCobro;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_vm._v(" Ver detalles ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"secondary","icon":""},on:{"click":function($event){_vm.tab = 2;
_vm.detailsId = item.IdCarteraCobro;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-send-outline")])],1)]}}],null,true)},[_vm._v(" Gestión de avisos formales ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }